import { navigate } from "@reach/router"

const transitionDelay = 0

export const onRouteUpdate = ({ location, prevLocation }, options) => {
    if (!location.search && prevLocation?.search)
        navigate(location.pathname + prevLocation.search, { replace: true })
}

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    if (location.action === 'PUSH') {
        window.setTimeout(() => window.scrollTo(0, 0), transitionDelay)
    } else {
        const savedPosition = getSavedScrollPosition(location)
        window.setTimeout(
            () => window.scrollTo(...(savedPosition || [0, 0])),
            transitionDelay
        )
    }
    return false
}

export const onServiceWorkerUpdateReady = () => {
    const answer = window.confirm(
        `Le site a été mis à jour !` +
        `Souhaitez-vous rafraîchir la page pour la mettre à jour ?`
    )
    if (answer === true) {
        window.location.reload()
    }
}